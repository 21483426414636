import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  PaperProps,
  Stack,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
  GRAIN_SOURCES,
  GRAIN_SOURCES_LIST,
  PRESET_PACKAGE_NONE,
  STORE_ITEM_FREE,
} from '../../constants';
import useToast from '../../hooks/useToast';
import ApiService, { PresetPackage } from '../../services/ApiService';
import { useAppSelector } from '../../store';
import { setIsAddPresetDialogOpen } from '../../store/appSlice';
import { Artist, Preset, StoreItem } from '../../types';
import AutocompleteField from '../fields/AutocompleteField';
import FileUploadField from '../fields/FileUploadField';
import ImageUploadField from '../fields/ImageUploadField';
import Select from '../fields/SelectField';

interface PaperPropsType extends PaperProps {
  component: 'form';
}

export interface PresetFormProps {
  artist?: Artist;
  artistId?: number;
  name: string;
  description: string;
  grainSource: string;
  grainValue?: number;
  storeItem: StoreItem;
  presetPackage: PresetPackage | (typeof PRESET_PACKAGE_NONE)['name'];
  storeItemId?: number;
  squareImageFilter?: File | string;
  coverBefore?: File | string;
  coverAfter?: File | string;
  fileFilter?: File | string;
  isFree: boolean;
}

interface FormProps {
  refetch: () => any;
  onClose: () => void;
  preset?: Preset;
  defaultValues?: Partial<PresetFormProps>;
}

export default function EditPresetForm({
  refetch,
  onClose,
  preset,
  defaultValues,
}: FormProps) {
  const toast = useToast();
  const isUpdate = defaultValues !== undefined;
  const isOpen = useAppSelector((state) => state.app.isAddPresetDialogOpen);
  const dispatch = useDispatch();

  const {
    register,
    control,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm<PresetFormProps>({ defaultValues });

  const [isInitialized, setIsInitialized] = useState(false);
  const [artistQuery, setArtistQuery] = useState('');

  const watchGrainSource = watch('grainSource');
  const watchArtist = watch('artist');

  const savePresetMutation = useMutation(
    isUpdate ? 'updatePreset' : 'createPreset',
    (data: PresetFormProps) =>
      isUpdate
        ? ApiService.updatePreset(preset?.id ?? 0, data)
        : ApiService.createPreset(data),
    {
      onError(error, variables, context) {
        toast(DEFAULT_ERROR_MESSAGE);
      },
      onSuccess(data, variables, context) {
        toast(DEFAULT_SUCCESS_MESSAGE, 'success');
        refetch();
        onFormClose();
      },
    }
  );

  const { isLoading } = savePresetMutation;

  const { isLoading: isArtistsLoading, data: artistsData } = useQuery(
    `artist-${artistQuery}`,
    () => ApiService.getArtists(100, 0, artistQuery)
  );

  const artists = artistsData?.objects ?? [];

  const { isLoading: isStoreItemsLoading, data: storeItems } = useQuery(
    `storeItems`,
    async () => {
      const res = await ApiService.getStoreItems();

      return [STORE_ITEM_FREE, ...res];
    }
  );

  const { isLoading: isPresetPackagesLoading, data: presetPackages } = useQuery(
    `presetPackages`,
    async () => {
      const res = await ApiService.getPresetPackages();

      return [PRESET_PACKAGE_NONE, ...res];
    }
  );

  const onFormClose = () => {
    dispatch(setIsAddPresetDialogOpen(false));

    reset({
      artist: undefined,
      coverAfter: undefined,
      coverBefore: undefined,
      description: undefined,
      fileFilter: undefined,
      grainSource: undefined,
      grainValue: undefined,
      name: undefined,
      storeItem: undefined,
    });
    setIsInitialized(false);
    onClose();
    setArtistQuery('');
  };

  const onSubmit = (data: PresetFormProps) => {
    if (isLoading) {
      return;
    }

    savePresetMutation.mutate(data);
  };

  const DialogPaperProps: PaperPropsType = {
    component: 'form',
    onSubmit: handleSubmit(onSubmit),
  };

  useEffect(() => {
    if (!defaultValues || !isOpen) {
      reset();
      return;
    }

    const { artist, artistId, storeItem, ...values } = defaultValues;

    reset({
      ...values,
    });
  }, [defaultValues, isOpen]);

  useEffect(() => {
    if (!defaultValues || !storeItems) {
      return;
    }

    const { storeItemId } = defaultValues;

    const val =
      storeItems.find((el) => el.id === storeItemId) || STORE_ITEM_FREE;

    setValue('storeItem', val);
  }, [defaultValues, storeItems]);

  useEffect(() => {
    if (!defaultValues || !isOpen || isInitialized) {
      return;
    }

    if (!artists) {
      return;
    }

    const { artistId, artist } = defaultValues;

    let newArtist = artist;

    if (!artist && artistId) {
      newArtist = artists.find((el) => el.id === artistId);
    }

    if (newArtist) {
      setValue('artist', newArtist);
      setIsInitialized(true);
    }
  }, [defaultValues, artists]);

  console.log(watchArtist);

  return (
    <Dialog
      open={isOpen}
      onClose={onFormClose}
      fullWidth
      maxWidth="md"
      PaperProps={DialogPaperProps}
      keepMounted={false}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {isUpdate ? 'Edit Preset' : 'Create Preset'}
        <IconButton edge="end" onClick={onFormClose} disabled={isLoading}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box pt={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} container spacing={2}>
              <Grid item xs={12}>
                <AutocompleteField
                  control={control}
                  name="artist"
                  label="Artist"
                  rules={{ required: 'Please, choose artist' }}
                  disabled={isLoading}
                  formControlProps={{ fullWidth: true }}
                  autocompleteProps={{
                    onInputChange: (event, value: string) =>
                      setArtistQuery(value),
                    options: artists ?? [],
                    loading: isArtistsLoading,
                    isOptionEqualToValue: (option: Artist, value: Artist) =>
                      option.id === value.id,
                    getOptionLabel: (option: Artist | string) =>
                      typeof option === 'string' ? option : option.fullName,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={isLoading}
                  fullWidth
                  label="Name"
                  inputProps={{
                    ...register('name', { required: 'Please, enter name' }),
                  }}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <AutocompleteField
                  control={control}
                  name="presetPackage"
                  label="Package"
                  disabled={isLoading}
                  defaultValue={PRESET_PACKAGE_NONE}
                  formControlProps={{ fullWidth: true }}
                  autocompleteProps={{
                    options: presetPackages ?? [],
                    loading: isPresetPackagesLoading,
                    isOptionEqualToValue: (
                      option: PresetPackage,
                      value: PresetPackage
                    ) => option.id === value.id,
                    getOptionLabel: (option: PresetPackage | string) =>
                      typeof option === 'string' ? option : option.name,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={isLoading}
                  multiline
                  rows={5}
                  fullWidth
                  label="Description"
                  inputProps={{
                    ...register('description', {
                      required: 'Please, enter description',
                    }),
                  }}
                  error={Boolean(errors.description)}
                  helperText={errors.description?.message}
                />
              </Grid>

              <Grid item xs={watchGrainSource === GRAIN_SOURCES.NONE ? 12 : 6}>
                <Select
                  control={control}
                  name="grainSource"
                  label="Grain source"
                  defaultValue={GRAIN_SOURCES.NONE}
                  formControlProps={{ disabled: isLoading, fullWidth: true }}
                >
                  {GRAIN_SOURCES_LIST.map((source) => (
                    <MenuItem
                      key={`select-grain-source-${source}`}
                      value={source}
                    >
                      {source}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              {(!watchGrainSource ||
                watchGrainSource !== GRAIN_SOURCES.NONE) && (
                <Grid item xs={6}>
                  <TextField
                    disabled={isLoading}
                    fullWidth
                    label="Grain value"
                    type="number"
                    defaultValue={1}
                    inputProps={{
                      ...register('grainValue', {
                        valueAsNumber: true,
                        required: 'Please, enter a grain value',
                        validate: (value) =>
                          value && value > 0
                            ? true
                            : 'The value must be greater than 0',
                      }),
                      step: 0.1,
                    }}
                    error={Boolean(errors.grainValue)}
                    helperText={errors.grainValue?.message}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={watch('isFree')}
                      value={watch('isFree')}
                      {...register('isFree')}
                      disabled={isLoading}
                      defaultChecked={false}
                    />
                  }
                  label="Free"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={2} height="100%">
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  flex="0 0 auto"
                  spacing={2}
                >
                  <ImageUploadField
                    control={control}
                    ratio={3 / 4}
                    name="coverBefore"
                    label="Cover Before"
                    rules={{ required: 'Please, select image' }}
                    formControlProps={{ disabled: isLoading }}
                  />
                  <ImageUploadField
                    control={control}
                    ratio={3 / 4}
                    name="coverAfter"
                    label="Cover After"
                    rules={{ required: 'Please, select image' }}
                    formControlProps={{ disabled: isLoading }}
                  />
                </Stack>

                <Stack
                  flex="1 1 auto"
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={2}
                >
                  <FileUploadField
                    label="Filter File PNG"
                    control={control}
                    name="fileFilter"
                    allowedExtensions={['image/png']}
                    rules={{ required: 'Please, select file' }}
                    formControlProps={{ disabled: isLoading }}
                  />
                  <FileUploadField
                    label="Square Image Filter"
                    control={control}
                    name="squareImageFilter"
                    allowedExtensions={['image/png']}
                    rules={{ required: 'Please, select file' }}
                    formControlProps={{ disabled: isLoading }}
                  />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container columnSpacing={2} rowSpacing={1} ml={0} mb={1}>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={2}
            ml="auto"
            order={{ xs: 2, sm: 1 }}
          >
            <Button
              fullWidth
              size="large"
              onClick={onFormClose}
              disabled={isLoading}
              color="inherit"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} order={{ xs: 1, sm: 2 }}>
            <LoadingButton
              variant="contained"
              fullWidth
              size="large"
              type="submit"
              loading={isLoading}
            >
              {isUpdate ? 'Save' : 'Create'}
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
